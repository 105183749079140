import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/web_proj1.css"
import { Link } from "gatsby"
import { FaCode, FaLink } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const web_proj1 = () => {
  return (
    <Layout>
      <SEO
        title="Restotante Con Fusion"
        description="Full Fledged Awesome Website Development (Web-Dev) project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/webprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="py-img-1">
          <div className="py-1-heading">
            <h1>Restotante Con Fusion</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/conFusion-React-Firebase"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        <div className="git">
          <a
            href="https://ristorante-con-fusion-food.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
            title="View live"
          >
            <FaLink className="git-btn"></FaLink>
          </a>
        </div>
        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It:</h3>
            <p>
              This is a south-indian based restaurant project, completely
              written in React.
              <br /> In this one can sign-in with google account. Even you can
              mark your dish as favourite, further you can update them from your
              favourite list.
              <br /> You can comment on any particular dish.
              <br />
              Next time you login through your account credentials, you will
              find all those changes as it is.
              <br />
              In this, The latest Cloud firestone (Firestone Beta) is used
              rather than old relational database.
              <br /> Done with awosome css effects.
              <br />
              <br />
              <br />
              At Front-end:-I did it in 2 flavours:-
              <br /> ={">"}Completely with Bootstrap <br />
              <div className="gitt">
                <a
                  href="https://github.com/arpit081999/Confusion-Bootstrap"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Github code"
                >
                  <FaCode className="gitt-btn"></FaCode>
                </a>
              </div>{" "}
              <br />={">"}Compeletely with React <br />
              <div className="gitt">
                <a
                  href="https://github.com/arpit081999/Confusion-React"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Github code"
                >
                  <FaCode className="gitt-btn"></FaCode>
                </a>
              </div>{" "}
              <br />
              At Backend:-I did it in 3 flavours:-
              <br /> ={">"}Firebase <br />
              <div className="gitt">
                <a
                  href="https://github.com/arpit081999/conFusion-React-Firebase"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Github code"
                >
                  <FaCode className="gitt-btn"></FaCode>
                </a>
              </div>{" "}
              <br />={">"}Loopback <br />
              <div className="gitt">
                <a
                  href="https://github.com/arpit081999/conFusion-React-Firebase"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Github code"
                >
                  <FaCode className="gitt-btn"></FaCode>
                </a>
              </div>{" "}
              <br />={">"}MongoDB, Express.js, Node.js (Full Fledged server side
              implementation (MERN stack))
              <br />
              <div className="gitt">
                <a
                  href="https://github.com/arpit081999/conFusion-React-Firebase"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Github code"
                >
                  <FaCode className="gitt-btn"></FaCode>
                </a>
              </div>{" "}
              <br />
              (So, yes its 5 project in 1).
              <br />
            </p>

            <h3>Skills Behind It:</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>JavaScript</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>CSS3</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>React</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>BootStrap</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>React-Router</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Firebase</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Loopback</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>MongoDB</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Express.js</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Node.js</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Auth</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default web_proj1
